import React from 'react';
import ModalComponent from '../../Modal';
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import '../../../styles/programs/functionalFitnessPopup/index.css'; 

const FunctionalFitnessPopup = ({ modalIsOpen, closeModal }) => {

  return (
    <div>
      <ModalComponent isOpen={modalIsOpen} closeModal={closeModal}>
        <div className="section-header-functionalFitnessPopup-container">
          <div className="title">Functional Tennis Fitness</div>
          <div className="icon" onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        <section className="functionalFitnessPopup-container">
          <div className='scrollable-box'>

            {/* Adults section*/}
            <div className="program-details">
              {/* <div className="program-item p-title">
                <div className="title">Adults Program</div>
              </div> */}
              <div className="program-item">
                <div className="details">
                  <div className="info">
                      <div>
                        <span className="value">For junior/adults.</span>
                      </div>
                      <div>
                        <span className="value">Session of 1 hour (150 Sar).</span>
                      </div>
                      <div>
                        <span className="value">Book one of our fitness course customized for tennis player and improve tour physical skills.</span>
                      </div>
                      <div>
                        <span className="value">Tennis shoes are mandatory.</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="divider"></div> */}

            {/* Family section*/}
            {/* <div className="program-details">
              <div className="program-item p-title">
                <div className="title">Family Program</div>
              </div>
              <div className="program-item">
                <div className="details">
                  <div className="info">
                      <div>
                        <span className="value">From 10 years old to adults.</span>
                      </div>
                      <div>
                        <span className="value">1 hour on the weekend 80 Sar per parent 40 Sar per kid.</span>
                      </div>
                      <div>
                        <span className="value">Enjoy a fitness session with your family on the tennis court.</span>
                      </div>
                      <div>
                        <span className="value">Tennis shoes are mandatory.</span>
                      </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div style={{ height: "30px" }}></div>
          </div>
        </section>
      </ModalComponent>
    </div>
  );
};

export default FunctionalFitnessPopup;
